
import { defineComponent, ref, onMounted, VNodeChild } from 'vue'
import {
  BarChartOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  AppstoreOutlined,
  IdcardOutlined,
  PieChartOutlined,
  CodepenOutlined,
  GlobalOutlined,
  BranchesOutlined,
  CloudServerOutlined,
  DatabaseFilled,
  DeploymentUnitOutlined
} from '@ant-design/icons-vue'
import { useRouter, useRoute } from 'vue-router'
import http, { Res } from '@/utils/http'

interface MenuInfo {
  key: string;
  keyPath: string[];
  item: VNodeChild;
  domEvent: MouseEvent;
}

export default defineComponent({
  name: 'Home',
  components: {
    BarChartOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    UserOutlined,
    AppstoreOutlined,
    IdcardOutlined,
    PieChartOutlined,
    CodepenOutlined,
    GlobalOutlined,
    BranchesOutlined,
    CloudServerOutlined,
    DatabaseFilled,
    DeploymentUnitOutlined
  },
  setup () {
    const user_name = ref('')
    const collapsed = ref(false)
    const router = useRouter()
    const route = useRoute()
    const selectedKeys = ref([route.path])
    const pid = ref(sessionStorage.getItem('pid'))
    const supper = ref(sessionStorage.getItem('supper') != null ? JSON.parse(sessionStorage.getItem('supper')!) : false)

    onMounted(() => {
      const name = sessionStorage.getItem('username')
      if (name) {
        user_name.value = name
      }
    })

    const logout = () => {
      http('/tokenapi/logout', {
        method: 'get',
        headers: {
          'x-token': sessionStorage.getItem('token')
        }
      }).then((res: any) => {
        if (res.code === 200) {
          sessionStorage.removeItem('token')
          router.push({ path: '/login' })
        }
      })
    }

    const handleClick = (e: MenuInfo) => {
      router.push({ path: e.key })
    }

    return { selectedKeys, collapsed, logout, user_name, handleClick, pid, supper }
  }
})
