<template>
  <a-layout>
    <a-layout-sider :collapsed="collapsed" :trigger="null" collapsible>
      <div v-if="!collapsed" class="logo"></div>
      <div v-if="collapsed" class="collapsed"></div>
      
      <!-- <img v-if="!collapsed" alt="Traceinstall" class="logo"> -->
      <!-- <img v-if="collapsed" alt="Traceinstall" class="collapsed"> -->
      <a-menu
        style="width: 250"
        mode="inline"
        theme="dark"
        v-model:selectedKeys="selectedKeys"
        @click="handleClick"
      >
        <a-menu-item key="/home/UserList">
          <UserOutlined />
          <span>用户列表</span>
        </a-menu-item>
        <a-menu-item v-if="supper" key="/home/invitaionCode">
          <CodepenOutlined />
          <span>邀请码列表</span>
        </a-menu-item>
        <a-menu-item v-if="supper" key="/home/domainList">
          <GlobalOutlined />
          <span>域名列表</span>
        </a-menu-item>
        <a-menu-item v-if="supper" key="/home/appVersion">
          <BranchesOutlined />
          <span>配置APP版本</span>
        </a-menu-item>
        <a-menu-item v-if="supper" key="/home/patchesUrl">
          <CloudServerOutlined />
          <span>配置补丁地址</span>
        </a-menu-item>
        <a-menu-item v-if="supper" key="/home/taichiConfig">
          <DeploymentUnitOutlined />
          <span>太极配置</span>
        </a-menu-item>
        <a-menu-item v-if="supper" key="/home/operationRecord">
          <DatabaseFilled />
          <span>操作记录</span>
        </a-menu-item>
      </a-menu>
    </a-layout-sider>
    <a-layout>
      <a-layout-header>
        <span>
          <menu-unfold-outlined
            v-if="collapsed"
            class="trigger"
            @click="() => (collapsed = !collapsed)"
          />
          <menu-fold-outlined v-else class="trigger" @click="() => (collapsed = !collapsed)" />
        </span>
        <div class="avatar">
          <a-popover trigger="click">
            <template #content>
              <div class="logout" style="cursor: pointer" @click="logout">登出</div>
            </template>
            <div class="user">{{user_name}}</div>
          </a-popover>
          <a-avatar>
            <template #icon>
              <UserOutlined />
            </template>
          </a-avatar>
        </div>
      </a-layout-header>
      <a-layout-content :style="{ margin: '18px 16px', padding: '18px', background: '#fff', minHeight: '280px' }">
        <a-layout-content>
          <div class="content">
            <router-view></router-view>
          </div>
        </a-layout-content>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, VNodeChild } from 'vue'
import {
  BarChartOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  AppstoreOutlined,
  IdcardOutlined,
  PieChartOutlined,
  CodepenOutlined,
  GlobalOutlined,
  BranchesOutlined,
  CloudServerOutlined,
  DatabaseFilled,
  DeploymentUnitOutlined
} from '@ant-design/icons-vue'
import { useRouter, useRoute } from 'vue-router'
import http, { Res } from '@/utils/http'

interface MenuInfo {
  key: string;
  keyPath: string[];
  item: VNodeChild;
  domEvent: MouseEvent;
}

export default defineComponent({
  name: 'Home',
  components: {
    BarChartOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    UserOutlined,
    AppstoreOutlined,
    IdcardOutlined,
    PieChartOutlined,
    CodepenOutlined,
    GlobalOutlined,
    BranchesOutlined,
    CloudServerOutlined,
    DatabaseFilled,
    DeploymentUnitOutlined
  },
  setup () {
    const user_name = ref('')
    const collapsed = ref(false)
    const router = useRouter()
    const route = useRoute()
    const selectedKeys = ref([route.path])
    const pid = ref(sessionStorage.getItem('pid'))
    const supper = ref(sessionStorage.getItem('supper') != null ? JSON.parse(sessionStorage.getItem('supper')!) : false)

    onMounted(() => {
      const name = sessionStorage.getItem('username')
      if (name) {
        user_name.value = name
      }
    })

    const logout = () => {
      http('/tokenapi/logout', {
        method: 'get',
        headers: {
          'x-token': sessionStorage.getItem('token')
        }
      }).then((res: any) => {
        if (res.code === 200) {
          sessionStorage.removeItem('token')
          router.push({ path: '/login' })
        }
      })
    }

    const handleClick = (e: MenuInfo) => {
      router.push({ path: e.key })
    }

    return { selectedKeys, collapsed, logout, user_name, handleClick, pid, supper }
  }
})
</script>

<style lang="scss" scoped>
.ant-layout-header {
  height: 50px;
  line-height: 50px;
}
.content {
  height: calc(100vh - 122px);
  max-height: calc(100vh - 122px);
  overflow-y: scroll;
}

.trigger {
  font-size: 18px;
  line-height: 50px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  width: 160px;
  height: 25px;
  margin: 16px;
}
.collapsed {
  width: 30px;
  height: 25px;
  margin: 16px 25px;
}

.site-layout .site-layout-background {
  background: #fff;
}

.ant-layout-header {
  background: #fff;
  padding: 0;

  .avatar {
    margin: 0 20px;
    display: inline;
    float: right;

    .user {
      display: inline;
      margin-right: 20px;
      cursor: pointer;
    }
  }
}
</style>
